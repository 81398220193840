import { i18n } from 'next-i18next';
import formatNumber, { FormatNumberOptions } from './formatNumber';

const formatHectares = (
  value: number | null | undefined,
  options?: FormatNumberOptions & {
    approximate?: boolean | null;
  },
) => {
  const { locale, fallbackValue, ...restOptions } = options || {};

  return formatNumber(value, {
    maximumFractionDigits: 4,
    unit: 'ha',
    locale: locale || i18n?.language || 'fi',
    fallbackValue: fallbackValue ?? '-',
    ...restOptions,
  });
};

export default formatHectares;
