export const approxAbbrev: Record<string, string> = {
  fi: 'n.',
  sv: 'ca',
  en: 'approx.',
};

export type FormatNumberOptions = Intl.NumberFormatOptions & {
  locale?: string;
  fallbackValue?: string;
  approximate?: boolean | null;
  useGrouping?: boolean | null;
};

const formatNumber = (
  value: number | null | undefined,
  options?: FormatNumberOptions,
) => {
  // While Intl.NumberFormat accepts `unit` as an option
  // it doesn't support arbitrary units, only a preset
  // list of units:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/supportedValuesOf#supported_unit_identifiers
  //
  // So we remove the unit from the options and handle it
  // separately.
  const { unit, ...mergedOptions } = {
    locale: 'fi',
    style: 'decimal',
    fallbackValue: '',
    useGrouping: true,
    ...options,
  };

  const locale = mergedOptions.locale ?? 'fi';

  if (typeof value !== 'number' || Number.isNaN(value)) {
    return mergedOptions.fallbackValue;
  }

  let formatted = new Intl.NumberFormat(locale, mergedOptions).format(value);

  if (formatted && options?.approximate) {
    formatted = `${approxAbbrev[locale]} ${formatted}`;
  }

  if (formatted && unit) {
    formatted = `${formatted} ${unit}`;
  }

  return formatted;
};

/**
 * Some fields expect numbers, but also allow
 * text (e.g. road cost, shorline length). So we
 * format them with this formatter.
 *
 * If the value seems to be only a number
 * (with either . or , as decimal point),
 * then we format it with formatNumber. Otherwise
 * we return the given value, or an empty string if
 * null or undefined.
 */
export const formatMaybeNumberString = (
  value: string | null | undefined,
  options?: FormatNumberOptions,
) => {
  if (value === null || value === undefined) {
    return '';
  }
  if (/^\d+[.,]?\d*$/.test(value)) {
    return formatNumber(parseFloat(value.replace(',', '.')), options);
  }
  return value;
};

export default formatNumber;
