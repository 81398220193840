import { Box, BoxProps, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
import formatCurrency from '@/lib/formatCurrency';
import formatHectares from '@/lib/formatHectares';
import { listingTags } from '@/lib/listingUtils';
import { getLocalizedRegionName } from '@/lib/regions';
import { ListingPublicCardAttributesFragment } from '@/generated/graphql';

export interface BasicDataProps extends BoxProps {
  listing: Omit<
    ListingPublicCardAttributesFragment,
    '__typename' | 'properties'
  > & {
    properties: Omit<
      ListingPublicCardAttributesFragment['properties'][number],
      'property_image_publics'
    >[];
  };
  customTags?: string[];
}

const BasicData: FC<BasicDataProps> = ({
  listing,
  children,
  customTags,
  ...boxProps
}) => {
  const { t } = useTranslation('frontoffice');
  const tags = customTags ?? listingTags(listing, t);
  const { locale } = useRouter();

  return (
    <Box p={3} {...boxProps}>
      {tags.map((tag, index) => (
        <Text
          textTransform="uppercase"
          fontSize="12px"
          mb={1}
          color="gray.500"
          key={tag}
          display="inline"
        >
          {tag} {tags.length === index + 1 ? '' : ' / '}
        </Text>
      ))}

      <Text as="h2" fontWeight="600" color="black">
        {children}
      </Text>
      <Text mb={2}>
        {Array.from(
          new Set(
            listing.properties.map((property) =>
              getLocalizedRegionName(property.property_municipality, locale),
            ),
          ),
        ).join(', ')}
      </Text>

      <SimpleGrid columns={2}>
        <Box>
          <Text mr={5} fontSize="lg" fontWeight="600" color="primary.500">
            {formatCurrency(listing.total_price as number, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              fallbackValue: '-',
            })}
          </Text>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="600" color="primary.500">
            {formatHectares(listing.total_area_in_hectares as number, {
              approximate: listing.has_approximate_area,
            })}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default BasicData;
